import React from "react";
import { SEO, Image, MarkdownContent } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class ProductsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.musesalonandspa.com/products/"}
        />
        <Grid
          className={"custom-page-products custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"sdazdxtmautzbtzv full-width"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
            reversed={""}
          >
            <Grid.Column
              className={"szmsrepllluewnuu"}
              style={{ padding: 16 }}
              width={16}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "500px" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "aa268018-d402-46ac-a83b-e455c17ab2d0",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      background: "rgba(0,0,0,0.42)",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "a0bf070a-e343-45e0-b375-58ddcaba87e6",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"mvggutugpijnqxiw"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"swnusvhhtxfcvjnq"}
              style={{ padding: 16 }}
              width={7}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "746ad73a-4301-41ed-99ea-b3eacf85b377",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"paaeclwyzrfbwtkc"}
              style={{ padding: 16 }}
              width={1}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <div className={""} style={{}} />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"iahwukpighccmevs"}
              style={{ padding: 16 }}
              width={7}
              textAlign={"justified"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "3da1b5ba-3a14-4c4a-9f78-758f3a7b3f99",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"ljsvonbevrhlirre"}
              style={{ padding: 16 }}
              width={1}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <div className={""} style={{}} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"svsdlercmtphrxwh"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
            reversed={"mobile"}
          >
            <Grid.Column
              className={"ttrbwgdulwfanefy"}
              style={{ padding: 16 }}
              width={1}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <div className={""} style={{}} />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"ucitzrgkvzdonrxl"}
              style={{ padding: 16 }}
              width={7}
              textAlign={"justified"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "21449d08-43d9-4212-87bd-b16cdc53d7d1",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"kqbwyvtpqseutlxi"}
              style={{ padding: 16 }}
              width={1}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <div className={""} style={{}} />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"uiabugbkggdhkugm"}
              style={{ padding: 16 }}
              width={7}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "e3f220dd-f0ad-4af1-8680-b074750525e5",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"qnnlbyuutjuglqvt"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"yagurpeumwspytwj"}
              style={{ padding: 16 }}
              width={7}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "505f866f-3753-4ede-a8f2-5b50896c132f",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"vovefhxadpglqarc"}
              style={{ padding: 16 }}
              width={1}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <div className={""} style={{}} />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"qonaxooqewbltgsv"}
              style={{ padding: 16 }}
              width={7}
              textAlign={"justified"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "23768510-317a-4760-8b24-05917e044106",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"dlpatqmqqipmxwwj"}
              style={{ padding: 16 }}
              width={1}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <div className={""} style={{}} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"qxysdigcoxczgcqf"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
            reversed={"mobile"}
          >
            <Grid.Column
              className={"obcgwlkwaotrvukp"}
              style={{ padding: 16 }}
              width={1}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <div className={""} style={{}} />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"khyvehvljyfvwgqt"}
              style={{ padding: 16 }}
              width={7}
              textAlign={"justified"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "de44aa08-d870-4cd0-a355-30db0408d909",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"lonbgntajmemptyi"}
              style={{ padding: 16 }}
              width={1}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <div className={""} style={{}} />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"ejdnwwmnzyffznhy"}
              style={{ padding: 16 }}
              width={7}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "d0073005-5035-4cb5-9fdb-aab43825b547",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 30374 }) {
      title
      seoTitle
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
